@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Inter var";
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url("../fonts/Inter-roman.var.woff2?v=3.19") format("woff2");
  font-named-instance: "Regular";
}

.loading-indicator {
  &:before {
    content: "";
    background: #000000cc;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 9999;
  }

  &:after {
    content: "Loading";
    position: fixed;
    width: 100%;
    top: 50%;
    left: 0;
    z-index: 1001;
    color: white;
    text-align: center;
    font-weight: bold;
    font-size: 1.5rem;
    z-index: 9999;
  }
}

body {
  font-family: "Inter var";
  font-feature-settings: "salt" !important;
  font-size: 13px;

  #root {
    height: 100vh;
  }

  .page-container {
    @apply mx-auto  px-2 flex max-w-screen-xl;
  }

  .auth-container {
    background-color: #f5f7fb;
    height: 100vh;
    display: flex;
    width: 100%;
  }
}

.login-container {
  .logo-content {
    background: #001e26;
    width: 100%;
    padding: 15px 20px;
  }

  .login-button {
    @apply bg-[#10b981] text-white;
    @apply hover:bg-[#10b981] text-white;
    text-transform: inherit;
  }
}

.kod-box {
  background: #ffffff;
  margin: 10px 0px 20px;
  border-radius: 6px;
  box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.1),
    0px 1px 10px 0px rgba(0, 0, 0, 0.08);
  overflow: hidden;
}

.app-container {
  .app-header {
    background-color: #001e26;
    padding: 14px;

    .header-menu {
      .my-account-menu {
        @apply flex;
      }
    }
  }
}

.MuiTextField-root {
  label,
  input {
    font-size: 14px;
  }
}

.dialog-container {
  #dialog-title {
    font-size: 15px;
    border-bottom: 1px solid #ededed;
  }

  .MuiDialogActions-root {
    padding: 12px 24px 12px 12px;
    background: rgb(246, 246, 246);
    border-top: 1px solid rgb(217, 217, 217);
  }

  .close-button {
    border: 1px solid rgba(97, 97, 97, 0.5);
    color: rgb(97, 97, 97);
    font-size: 12px;

    &:hover {
      text-decoration: none;
      background-color: rgba(97, 97, 97, 0.04);
      border: 1px solid rgb(97, 97, 97);
    }
  }

  .save-button {
    background: #10b981;
    box-shadow: none;
    font-size: 12px;

    &:hover {
      text-decoration: none;
      box-shadow: none;
      background: #10b981;
    }
  }
}

#account-menu {
  .MuiPaper-root {
    box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08),
      0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
    background-color: #ffffff;
    background-clip: padding-box;
    border-radius: 8px;
    padding: 4px;

    .profile-info {
      @apply flex flex-col p-4;

      .profile-name {
        @apply w-full text-[14px] font-semibold;
      }
      .profile-email {
        @apply w-full text-xs font-normal;
      }
    }

    .MuiList-root {
      padding: 0px;
    }

    .MuiDivider-root {
      margin: 4px 0;
      border-color: rgba(5, 5, 5, 0.06);
    }

    .MuiMenuItem-root {
      font-size: 14px;
      padding-right: 20px;
      border-radius: 4px;

      .MuiListItemIcon-root {
        min-width: 30px;

        svg {
          width: 18px;
        }
      }
    }
  }
}

.notistack-SnackbarContainer {
  .MuiButtonBase-root {
    margin-right: 5px;
    width: 30px;
    height: 30px;
    color: #fff;

    svg {
      width: 18px;
    }
  }
}

#react-confirm-alert {
  .react-confirm-alert-overlay {
    background-color: #001e2659;
  }

  .react-confirm-alert {
    .react-confirm-alert-body {
      @apply p-0 overflow-hidden;
      border-radius: 4px;
      box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2),
        0px 24px 38px 3px rgba(0, 0, 0, 0.14),
        0px 9px 46px 8px rgba(0, 0, 0, 0.12);
      border-bottom: solid 7px #e74c3c;
      font-family: "Inter var";

      > h1 {
        font-size: 22px;
        line-height: 1.6;
        padding: 30px 24px 0;
        font-weight: bold;
        color: #001e26;
        text-align: center;
      }

      .react-confirm-alert-message {
        @apply p-6 text-[15px] text-center font-normal;
      }

      .react-confirm-alert-button-group {
        margin: 0px 0 20px;
        display: flex;
        justify-content: center;
        @apply space-x-2;

        > button {
          margin: 0;
          font-weight: 500;
          padding: 10px 15px;

          &.cancel-button {
            background-color: #e74c3c;
          }

          &.confirm-button {
            background-color: #34495e;
          }
        }
      }
    }
  }
}

.kod-table-content {
  box-shadow: none !important;
  background-color: transparent !important;

  .MuiToolbar-root {
    padding: 0;

    div[class$="MUIDataTableToolbar-left"] {
      display: flex;
      justify-items: left;
    }
  }

  table {
    border: 1px solid #b6e4b1;
    border-radius: 6px;

    thead {
      background-color: #dbfde6;
      border-top: 1px solid #b6e4b1;

      tr {
        th {
          padding: 0 15px;
          font-weight: 600;
          line-height: 40px;
          background: transparent;
          @apply border-[#b6e4b1] border-l;
        }
      }
    }

    tbody {
      tr {
        @apply bg-[#fff8f2] border-[#b6e4b1] border-b;
        &:hover {
          background-color: #fff8f2 !important;
        }

        td {
          @apply border-[#b6e4b1] border-l;
          padding: 2px 15px;
        }

        td:has(.table-action-buttons) {
          width: 200px;
        }

        &:nth-child(2n) {
          background-color: #fff;
          &:hover {
            background-color: #fff !important;
          }
        }
      }
    }

    tfoot {
      background-color: #dbfde6;
    }
  }

  .kod-table-custom-header {
    @apply flex justify-end space-x-2;

    .kod-table-add-button,
    .kod-table-download-button {
      color: #10b981;
      border: 1px solid #10b981;
      padding: 5px 15px;
      text-transform: inherit;
      border-radius: 6px;
    }
  }

  .kod-table-search-input {
    width: 250px;
    .MuiInputBase-root {
      @apply rounded-md border-[#b6e4b1] border;

      .MuiInputAdornment-root {
        svg {
          width: 18px;
          color: #81c27a;
        }
      }

      .MuiInputBase-input {
        padding: 4px 0 6px;
      }
      fieldset {
        border: 0px;
      }
      &:hover {
        border-color: #10b981;

        .MuiInputAdornment-root {
          svg {
            color: #10b981;
          }
        }
        fieldset {
          text-decoration: none;
        }
      }

      &.Mui-focused {
        fieldset {
          text-decoration: none;
          border: 0px;
        }
      }
    }
  }

  .table-action-buttons {
    display: flex;
    justify-content: flex-end;
    padding: 6px 0;

    button {
      min-width: auto;
      color: #adadad;
      border-radius: 4px;
      text-transform: inherit;
      border: 1px solid #a9a9a9;
      color: #4e4e4e;
      background-color: #fff;
      padding: 3px 10px;

      svg {
        width: 16px;
      }

      &.remove-button {
        margin-left: 8px;

        &:hover {
          border: 1px solid #be0000;
          color: #be0000;
        }
      }
      &.feature-button {
        margin-right: 8px;

        &:hover {
          border: 1px solid #00be3f;
          color: #00be3f;
        }
      }
      &.edit-button:hover {
        border: 1px solid #0d0071;
        color: #0d0071;
      }
      &.match-button {
        margin-top: 8px;
        &:hover {
          border: 1px solid #0d0071;
          color: #0d0071;
        }
      }
    }
  }
}

.MuiAutocomplete-root {
  background-color: #fff;
}

.MuiAutocomplete-popper {
  font-size: 12px;
}
