.create-product-dialog {
  .module-type-item {
    @apply pt-4 rounded-md overflow-hidden cursor-pointer min-h-[60px] text-center flex flex-col items-center justify-center;
    border: 1px solid #efefef;

    img {
      width: 80%;
      filter: grayscale(100%);
      opacity: 0.6;
    }

    &.selected {
      border-color: #10b981;
      img {
        filter: grayscale(0%);
        opacity: 1;
      }
    }

    .module-category {
      width: 100%;
      background-color: #fafafa;
      padding: 3px;
      margin-top: 10px;
      font-size: 11px;
      color: #737373;
    }
  }

  .slide-container {
    @apply bg-gray-50 overflow-hidden rounded-md shadow-md;

    .slide-item {
      @apply h-72;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
    }

    &:hover {
      .default-nav {
        display: flex;
      }
    }

    .default-nav {
      display: none;
      @apply rounded-sm;

      &.nav:first-of-type {
        left: 10px;
      }

      &.nav:last-of-type {
        right: 10px;
      }

      svg {
        width: 14px;
      }
    }
  }
}

.app-module-content {
  background-color: #f5f7fb;
  @apply py-3;

  .menu-module-list {
    display: flex;

    .module-item {
      @apply flex items-center cursor-pointer h-8  bg-white mx-1 rounded-xl px-4;
      box-shadow: 1px 1px 1px 0 rgba(0, 0, 0, 0.05);

      .module-icon {
        display: none;
        font-size: 16px;

        svg {
          width: 18px;
        }
      }

      .module-name {
        font-weight: 500;
      }

      &.selected {
        background-color: #10b981;
        color: #fff;
      }
    }
  }

  .module-create-button {
    padding: 8px;
    background-color: #fff;
    border: 1px dashed #ccc;
    height: 30px;
    min-width: 30px;
    border-radius: 100%;
    margin-left: 5px;
    svg {
      width: 15px;
      color: #001e26;
    }
  }
}
